<template>
  <PengawasanDekomForm mode="Ubah" module="Laporan Tugas Pengawasan Dekom"> </PengawasanDekomForm>
</template>

<script>
import PengawasanDekomForm from './form';

const PengawasanDekomUpdate = {
  name: 'PengawasanDekomUpdate',
  components: { PengawasanDekomForm },
};

export default PengawasanDekomUpdate;
</script>
